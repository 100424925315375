export enum KeywordDataType {
    Null,
    LargeNumeric,
    AlphaNumeric,
    Currency,
    Date,
    Float,
    SmallNumeric,
    DateTime,
    AlphaNumericSingleTable,
    SpecificCurrency,
    AlphaNumericCSInsensitiveSearch,
    AlphaNumericSingleTableCSInsensitiveSearch
}
