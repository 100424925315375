import { DisplayColumnValue } from "../../shared";

type DisplayColumns = { [key: string]: string };

export class Document {
    public readonly docID: string;
    public readonly docChecksum: string;
    public readonly docIV: string;
    public readonly docName: string;
    public readonly displayType: string;
    public readonly displayColumnValues?: DisplayColumnValue[];

    public readonly isFullText: boolean;
    public readonly fullTextScore?: number;
    public readonly fullTextSummary?: string;

    constructor(init: {
        docID: string,
        docName: string,
        displayType: string,
        displayColumnValues?: DisplayColumnValue[],
        fullTextScore?: number,
        fullTextSummary?: string
    }) {
        this.docID = init.docID;
        this.docName = init.docName;
        this.displayType = init.displayType;
        this.displayColumnValues = init.displayColumnValues;

        if (init.fullTextScore) {
            this.isFullText = true;
            this.fullTextScore = init.fullTextScore;
            this.fullTextSummary = init.fullTextSummary;
        } else {
            this.isFullText = false;
        }
    }
}
