import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { KeywordDataType } from "../../shared";
import { SearchFormModel, 
        SearchFormService, SearchFormUtilService } from "../internal";

@Component({
    selector: "obpa-search-form",
    templateUrl: "./search-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchFormUtilService, SearchFormService]
})
export class SearchFormComponent implements OnChanges {
    constructor(
        private changeDetector: ChangeDetectorRef, 
        public searchForm: SearchFormService,
        private searchFormUtil: SearchFormUtilService
    ) { }

    @Input()
    searchFormModel: SearchFormModel;
    
    @Output()
    search = new EventEmitter();
    
    form: FormGroup;
    ngOnChanges(changes: any) {
        this.initForm();
    }
    
    initForm() {
        this.form = this.searchFormUtil.toFormGroup(this.searchFormModel);
        this.form.valueChanges.subscribe(formValue => this.onFormValueChange());
        
        this.searchForm.showErrors = false;
    }

    onFormValueChange() {
        if (this.searchForm.showErrors) {
            this.searchForm.showErrors = false;
        }
        this.changeDetector.markForCheck();
    }

    onReset() {
        this.initForm();
    }
    
    onSubmit(form: FormGroup) {
        // swap From/To Date if they are backwards
        this.searchFormUtil.normalizeDateRange(form);

        if (form.valid) {
            this.searchForm.showErrors = false;
            this.search.emit({
                value: this.searchFormUtil.toSearchModel(form.value)
            });
        } else {
            this.searchForm.showErrors = true;
        }
    }
    KeywordDataType = KeywordDataType;
}
