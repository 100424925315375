import { NgModule } from "@angular/core";

import { SharedModule } from "../shared";

import { DocumentHitlistComponent, DocumentHitlistGridComponent, DocumentViewComponent,
         TrustHtmlPipe, TrustResourceUrlPipe } from "./internal";

import { AutoFocus } from './auto-focus.directive';

// We want this Module's providers scoped to this module and NOT added to AppModule.
// So, set them in the top-level @Component decorator rather than @NgModule.

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        DocumentHitlistComponent,
        DocumentHitlistGridComponent,
        DocumentViewComponent,
        TrustHtmlPipe,
        TrustResourceUrlPipe,
        AutoFocus
    ],
    entryComponents: [
        DocumentHitlistComponent
    ]
})
export class DocumentHitlistModule {}
