import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
    ChangeDetectionStrategy, NgZone
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import {
    Column, BoundColumn, UnboundColumn,
    DocumentHitlistConfigService, ViewerMode, DocumentHitlistApiService
} from "../internal";

import {
    DisplayColumn, DisplayColumnValue, Document,
    KeywordDataType
} from "../../shared";

@Component({
    selector: "obpa-document-hitlist-grid",
    templateUrl: "./document-hitlist-grid.component.html",
    styleUrls: ["./document-hitlist-grid.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentHitlistGridComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    documents: Document[];

    @Input()
    columns: DisplayColumn[];

    @Input()
    isFullText: boolean;

    @Input()
    isMobileBrowser: boolean;

    @Output() documentOpen = new EventEmitter<Document>();
    @Output() documentOpenInNewWindow = new EventEmitter<Document>();

    gridElement?: JQuery;
    rowsWithPDFDocuments: number[] = [];
    documentViewerModeMap : Map<number, ViewerMode> = new Map<number, ViewerMode>();
    documentNameMap: Map<number, string> = new Map<number, string>();

    constructor(
        private zone: NgZone,
        private api: DocumentHitlistApiService,
        private config: DocumentHitlistConfigService,
        private translate: TranslateService) { }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            if (!this.gridElement) {
                this.initializeGrid();
            }
        });

        this.translate.onLangChange.subscribe(() => {
            this.zone.runOutsideAngular(() => this.retranslateGrid());
        });
    }

    ngOnChanges(changes: any) {
        this.zone.runOutsideAngular(() => {
            this.destroyGrid();
            this.initializeGrid();
        });
    }

    ngOnDestroy() {
        this.destroyGrid();
    }

    initializeGrid() {
        let displayData = this.generateDisplayData();

        this.gridElement = $('#obpa-grid').igGrid({
            autoGenerateColumns: false,
            autofitLastColumn: true,
            primaryKey: "obpa_id",
            
            columns: displayData.columns,
            dataSource: displayData.documents,            

            alternateRowStyles: false,

            // we will control the tab-order elsewhere
            tabIndex: -1,

            features: <IgGridFeature[]>[
                <IgGridSorting>{
                    name: "Sorting",

                    // remove blue style on sorted column
                    applySortedColumnCss: false,

                    // each search should default to database sort
                    persist: false,

                    columnSettings: <IgGridSortingColumnSetting[]>[
                        { columnKey: "obpa_action", allowSorting: false },
                        { columnKey: "obpa_gap", allowSorting: false }
                    ]
                },
                <IgGridSelection>{
                    name: "Selection",

                    mode: "row",

                    wrapAround: false,

                    // only use the "activeRow" functionality, ignore any row selections
                    rowSelectionChanging: (event, ui) => {
                        return false;
                    }
                },
                <IgGridResizing>{
                    name: "Resizing",

                    columnSettings: <IgGridResizingColumnSetting[]>[
                        { columnKey: "obpa_action", allowResizing: false },
                        { columnKey: "obpa_gap", allowResizing: false }
                    ]
                }
            ],

            cellClick: (event, ui) => {
                this.emitDocumentOpen(ui.rowKey);
            },

            columnsCollectionModified: (event, ui) => {
                 let grid: IgGridMethods = ui.owner;
                 let gridElement: JQuery = ui.owner.element;

                // the first cell should be tab-able
                gridElement.find("thead:eq(0) th").attr("tabindex", "-1").first().attr("tabindex", "0");
            },

            rowsRendered: (event, ui) => {
                let grid: IgGridMethods = ui.owner;
                let gridElement: JQuery = ui.owner.element;

                let gridRows: JQuery = grid.rows() as any;

                // add action buttons
                this.populateActionCells(gridRows);
                // add pdf icon
                this.populateIconCells(gridRows);
                this.modifyRolesOnGridDataCells(gridRows);

                // the first row should be tab-able and focused
                const firstRow = gridRows.first()[0] as HTMLTableRowElement;
                if (firstRow) {
                    firstRow.tabIndex = 0;
                    firstRow.focus();
                }

                grid.autoSizeColumns();
            },

            rendered: (event, ui) => {
                let grid: IgGridMethods = ui.owner;
                let gridElement: JQuery = ui.owner.element;

                // headers should come first in the DOM
                gridElement.find("tbody:eq(0)").before(gridElement.find("thead:eq(0)"));

                // keyboard navigation within the header
                gridElement.find("thead:eq(0)").keydown(event => {
                    let handled = false;

                    switch (event.keyCode) {
                        case 40: // down
                            (grid.rows() as any as JQuery).first().focus();
                            handled = true;
                            break;
                        case 37: // left
                            $(event.target).prev().focus();
                            handled = true;
                            break;
                        case 39: // right
                            $(event.target).next().focus();
                            handled = true;
                            break;
                    }

                    if (handled) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                });

                // keyboard navigation within the body
                gridElement.find("tbody:eq(0)").keydown(event => {
                    let handled = false;

                    let rowInfo: { rowId: number, rowIndex: number } = grid.getElementInfo(event.target) as any;

                    switch (event.keyCode) {
                        case 38: // up
                            if (rowInfo.rowIndex == 0) {
                                gridElement.igGridSelection("clearSelection");
                                gridElement.find("thead:eq(0) th").first().focus();
                                handled = true;
                            }
                            break;
                        case 13: // enter
                        case 32: // space
                            if (rowInfo.rowId) {
                                this.emitDocumentOpen(rowInfo.rowId);
                                handled = true;
                            }
                            break;
                    }

                    if (handled) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                });
            }
        });
        
        this.translateHeaders();

        this.modifyGridForAccessibility(this.gridElement);
    }

    destroyGrid() {
        if (this.gridElement) {
            this.gridElement.igGrid("destroy");
            this.gridElement = undefined;
        }
    }

    // We need to massage the Document list to get it in a format the IgGrid can consume.
    // 1) Create columns based on the current state of @Input's.
    // 2) Map each Document to an object, with properties matching the columns from (1).
    // Note that IgGrid does NOT consume the Document list directly, due to limitations mapping properties to columns.

    generateDisplayData(): { columns: Column[], documents: any[] } {
        return {
            columns: this.generateDisplayColumns(),
            documents: this.generateDisplayDocuments()
        };
    }

    generateDisplayColumns(): Column[] {
        let displayColumns: Column[] = [];

        // common columns
        displayColumns.push(new BoundColumn({
            key: "obpa_id",
            width: "34",
            visible: false
        }));
        if (!this.isMobileBrowser) displayColumns.push(new UnboundColumn({
            key: "obpa_action",
            width: "*",
            columnCssClass: "js-obpa_action"
        }));
        //pdf icon column
        if (!this.isMobileBrowser) displayColumns.push(new UnboundColumn({
            key: "obpa_icon",
            width: "*",
            columnCssClass: "js-obpa_icon"
        }));
        if (this.columns && this.columns.length > 0) {
            // create custom headers
            for (let i = 0, len = this.columns.length; i < len; i++) {

                // Sorting requires that the visible column will be of its type (NOT a string).
                // In cases (currency, date) the string we want to display does not quite match its backing value...
                // 1. create a hidden column, holding the string to display ($1,000.00)
                // 2. create a visible column, bound to the raw value (1000), BUT templating the hidden column

                let keyForData: string = `custom${i}`;
                let keyForDisplay: string = `${keyForData}display`;

                switch (this.columns[i].dataType) {
                    default:
                    case KeywordDataType.Null:
                    case KeywordDataType.AlphaNumeric:
                    case KeywordDataType.AlphaNumericSingleTable:
                    case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                    case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            dataType: "string"
                        }));
                        break;
                    case KeywordDataType.LargeNumeric:
                    case KeywordDataType.SmallNumeric:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;
                    case KeywordDataType.Currency:
                    case KeywordDataType.SpecificCurrency:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;                    
                    case KeywordDataType.Float:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;
                    case KeywordDataType.Date:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "date"
                        }));
                        break;
                    case KeywordDataType.DateTime:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "date"
                        }));
                        break;
                }
            }
        } else {
            // use default headers
            if (this.isFullText) displayColumns.push(new BoundColumn({
                key: "obpa_fulltext_score",
                width: "*",
                translateHeader: true,
                dataType: "number",
                format: "double"
            }));
            displayColumns.push(new BoundColumn({
                key: "obpa_name",
                width: "*",
                translateHeader: true
            }));
            if (this.isFullText) displayColumns.push(new BoundColumn({
                key: "obpa_fulltext_summary",
                width: "*",
                translateHeader: true
            }));
        }

        // With "autofitLastColumn", this empty column will...
        // 1) if table width <  100%, expand to fill the gap
        // 2) if table width >= 100%, not be visible
        displayColumns.push(new UnboundColumn({
            key: "obpa_gap"
        }));

        return displayColumns;
    }

    generateDisplayDocuments(): any[] {
        let displayDocuments: any[] = [];
        let counter: number = 0;
        if (this.columns && this.columns.length > 0) {
            // create custom headers
            displayDocuments = this.documents.map((document, index) => {
                let displayDocument: any = {
                    "obpa_id": index
                };
                for (let i = 0, len = this.columns.length; i < len; i++) {

                    // Sorting requires that the visible column will be of its type (NOT a string).
                    // In cases (currency, date) the string we want to display does not quite match its backing value...
                    // 1. create a hidden column, holding the string to display ($1,000.00)
                    // 2. create a visible column, bound to the raw value (1000), BUT templating the hidden column

                    let keyForData: string = `custom${i}`;
                    let keyForDisplay: string = `${keyForData}display`;

                    // this must exist in the array if the API is doing the right thing
                    let displayColumnValue: DisplayColumnValue = (document.displayColumnValues as any)[i];

                    switch (this.columns[i].dataType) {
                        default:
                        case KeywordDataType.Null:
                        case KeywordDataType.AlphaNumeric:
                        case KeywordDataType.AlphaNumericSingleTable:
                        case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                        case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                            // leave the value as it is
                            displayDocument[keyForData] = displayColumnValue.value;
                            break;
                        case KeywordDataType.LargeNumeric:
                        case KeywordDataType.SmallNumeric:
                            // parse and treat as number
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseInt(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Currency:
                        case KeywordDataType.SpecificCurrency:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseFloat(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Float:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseFloat(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Date:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? new Date(parseInt(displayColumnValue.raw)) : undefined;
                            break;
                        case KeywordDataType.DateTime:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? new Date(parseInt(displayColumnValue.raw)) : undefined;
                            break;
                    }
                }
                // add index of documents with display type PDF to array
                if(document.displayType === "PDF"){
                    this.rowsWithPDFDocuments.push(index);
                }
                //map the document's viewer mode on server with the document index
                this.api.tryDocumentUri(document).subscribe(
                    metadata => {
                        this.documentViewerModeMap.set(index, metadata.viewerMode);
                        counter++;
                        if(counter === this.documents.length) {
                            this.retranslateGrid();
                        }
                    }
                    
                );
                this.documentNameMap.set(index, document.docName);
                return displayDocument;
            });
        } else {
            // use default headers
            displayDocuments = this.documents.map((document, index) => {
                // add index of documents with display type PDF to array
                if(document.displayType === "PDF"){
                    this.rowsWithPDFDocuments.push(index);
                }
                //map the document's viewer mode on server with the document index
                this.api.tryDocumentUri(document).subscribe(
                    metadata => {
                        this.documentViewerModeMap.set(index, metadata.viewerMode);
                        counter++;
                        if(counter === this.documents.length) {
                            this.retranslateGrid();
                        }
                    }
                    
                );
                this.documentNameMap.set(index, document.docName);

                return {
                    "obpa_id": index,
                    "obpa_fulltext_score": document.fullTextScore,
                    "obpa_name": document.docName,
                    "obpa_fulltext_summary": document.fullTextSummary
                };
            });
        }
        
        return displayDocuments;
    }

    populateActionCells(rows: JQuery) {
        rows.each((index: number, _elem: Element) => {
            let elem = $(_elem);

            // add action buttons
            let id = +elem.attr("data-id")!;
            let cell = elem.find(".js-obpa_action");

            let cellContents = $("<div style='white-space:nowrap;'/>");

            // new window
            cellContents.append(
                $("<button/>")
                    .addClass("btn btn-xs btn-link")
                    .attr("title", this.translate.instant("OPEN IN NEW WINDOW"))
                    .attr("aria-hidden", "true")
                    .attr("tabindex", "-1")
                    .click(event => {
                        this.emitDocumentOpenInNewWindow(id);
                        event.preventDefault();
                        event.stopPropagation();
                    })
                    .keydown(event => {
                        // enter, space
                        if (event.keyCode === 13 || event.keyCode === 32) {
                            this.emitDocumentOpenInNewWindow(id);
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    })
                    .append("<span class='glyphicon glyphicon-new-window' aria-hidden='true'/>")
            );

            cell.empty().append(cellContents);
        });
    }

    //populate pdf icon cells
    populateIconCells(rows: JQuery) {

        rows.each((index: number, _elem: Element) => {
            let elem = $(_elem);
            let id = +elem.attr("data-id")!;
            let cell = elem.find(".js-obpa_icon");
            //prepare svg
            const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            const svgPath1 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            const svgPath2 = document.createElementNS("http://www.w3.org/2000/svg", 'path');

            svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            svg.setAttribute("viewBox", "0 0 48 48");
            svg.setAttribute("width", "16");
            svg.setAttribute("height", "16");
            svg.setAttribute("fill", "none"); 
            svg.setAttribute("role", "img");
            svg.setAttribute("aria-label", "view pdf of document " + this.documentNameMap.get(id) + " in modal window on same page");
            svg.setAttribute("tabindex", "-1");

            svgPath1.setAttribute("fill-rule", "evenodd");
            svgPath1.setAttribute("clip-rule", "evenodd");
            svgPath1.setAttribute("d", "M0 4C0 1.79086 1.79086 0 4 0H28L40 12V44C40 46.2091 38.2091 48 36 48H4C1.79086 48 0 46.2091 0 44V4ZM1.5 4C1.5 2.61929 2.61929 1.5 4 1.5H26.5V10C26.5 11.933 28.067 13.5 30 13.5H38.5V44C38.5 45.3807 37.3807 46.5 36 46.5H4C2.61929 46.5 1.5 45.3807 1.5 44V4Z");
            svgPath1.setAttribute("fill", "#DA1500");

            svgPath2.setAttribute("d", "M10.3978 38.5965C9.30275 37.4658 10.4875 35.8903 13.7186 34.2406L15.7471 33.2026L16.5369 31.4046C16.9677 30.4222 17.614 28.8096 17.973 27.8458L18.6192 26.0664L18.1884 24.7503C17.6319 23.1377 17.4345 20.691 17.7935 19.8198C18.2781 18.6335 19.8219 18.7633 20.4502 20.0237C20.9349 21.0246 20.881 22.8041 20.3066 25.084L19.8399 26.9375L20.2527 27.6604C20.4681 28.0682 21.1323 29.0135 21.7247 29.755L22.8197 31.1637L24.1839 30.9783C28.5101 30.4037 30 31.3861 30 32.8133C30 34.6113 26.6073 34.7596 23.7531 32.6836C23.1069 32.2202 22.6761 31.7568 22.6761 31.7568C22.6761 31.7568 20.881 32.1275 20.0014 32.3685C19.0859 32.628 18.6372 32.7763 17.3088 33.2397C17.3088 33.2397 16.8421 33.944 16.5369 34.4445C15.406 36.3351 14.0777 37.9107 13.1442 38.4853C12.0672 39.134 10.9542 39.1711 10.3978 38.5965ZM12.121 37.9663C12.7314 37.577 13.9879 36.0385 14.8495 34.6298L15.2086 34.0552L13.6109 34.8893C11.1517 36.1498 10.0208 37.3731 10.6132 38.096C10.9363 38.5038 11.3312 38.4667 12.121 37.9663ZM28.1511 33.3138C28.7614 32.8689 28.6716 31.9978 27.9895 31.6456C27.451 31.3676 27.0202 31.3119 25.638 31.3305C24.7943 31.3861 23.43 31.5714 23.1967 31.6271C23.1967 31.6271 23.9506 32.1646 24.2737 32.3685C24.7225 32.628 25.7995 33.1099 26.5894 33.3694C27.3612 33.5918 27.81 33.5733 28.1511 33.3138ZM21.7067 30.552C21.3477 30.1627 20.7194 29.3286 20.3245 28.717C19.804 28.0126 19.5526 27.5307 19.5526 27.5307C19.5526 27.5307 19.1757 28.7911 18.8705 29.5325L17.9012 31.9978L17.614 32.5538C17.614 32.5538 19.1039 32.0534 19.8578 31.8495C20.6656 31.6271 22.2812 31.2934 22.2812 31.2934L21.7067 30.552ZM19.6424 21.9514C19.7322 21.1544 19.7681 20.3388 19.5167 19.931C18.8167 19.134 17.973 19.8013 18.1166 21.6734C18.1704 22.3036 18.314 23.3787 18.5115 24.046L18.8885 25.2508L19.1398 24.3425C19.2834 23.8421 19.5167 22.767 19.6424 21.9514Z");
            svgPath2.setAttribute("fill", "#DA1500");

            svg.appendChild(svgPath1);
            svg.appendChild(svgPath2);
            // add icon
            let cellContents = $("<div style='white-space:nowrap;'/>");
            // get overridden viewer mode, otherwise use server's mode
            let viewerMode: ViewerMode | undefined = this.config.viewerModeOverride()
            if(!viewerMode){
                viewerMode = this.documentViewerModeMap.get(id);
            }
            if(viewerMode === ViewerMode.PDF || viewerMode === ViewerMode.NativeOptional) {
                cellContents.append(svg);
            }
            else {
                if(this.rowsWithPDFDocuments.includes(id)) {
                    svg.setAttribute("aria-label", "download pdf of document " + this.documentNameMap.get(id));
                    cellContents.append(svg);
                }
            }

            cell.empty().append(cellContents);
        });
}

    modifyGridForAccessibility(grid: JQuery) {
        grid.attr("role", null);
        grid.find("thead:eq(0)").attr("role", null);
        grid.find("thead:eq(0) tr").attr("role", null);
        let colHeadings = grid.find("thead:eq(0) th") as JQuery;
        this.modifyRolesOnColumnHeadings(colHeadings);
        grid.find("tbody:eq(0)").attr("role", null);
        let rowElements = grid.find("tbody:eq(0) tr") as JQuery;
        this.modifyRolesOnGridDataCells(rowElements);
        grid.find("tfoot:eq(0)").attr("role", null);
    }

    modifyRolesOnColumnHeadings(headings: JQuery) {
        headings.each((index: number, _headingElem: Element) => {
            let headingElem = $(_headingElem);
            switch (index) {
                case 0: //first column containing Open In New Window button
                    headingElem.attr("role", null);
                    break;
                case headings.length - 1: //last column (gap)
                    headingElem.attr("role", "presentation");
                    break;
                default:
                    headingElem.attr("role", "button");
                    headingElem.attr("scope", "col");
            }
        });
    }

    modifyRolesOnGridDataCells(rows: JQuery) {
        rows.each((index: number, _rowElem: Element) => {
            let rowElem = $(_rowElem);
            rowElem.attr("role", "button");
            let cells = rowElem.find("td") as JQuery;
            cells.each((index: number, cell: Element) => {
                $(cell).attr("role", null);        
            });
        });   
    }

    emitDocumentOpen(id: number) {
        this.zone.run(() => {
            let document = this.documents[id];
            this.documentOpen.emit(document);
        });
    }

    emitDocumentOpenInNewWindow(id: number) {
        this.zone.run(() => {
            let document = this.documents[id];
            this.documentOpenInNewWindow.emit(document);
        });
    }

    translateHeaders() {
        if (!this.gridElement) return;

        // gather necessary keys
        let keys: string[] = (this.gridElement.igGrid("option", "columns") as Column[])
            .filter(column => column.translateHeader)
            .map(column => `HDR-${column.key}`);
        if (keys.length === 0) return;

        let translatedKeys = this.translate.instant(keys);

        // each <th> has id="{gridId}_{columnKey}"
        let columnHeaderKeyPosition = this.gridElement.attr("id")!.length + 1;

        // gather existing header elements (and ignore the mistyped headersTable)
        let columnHeaders: JQuery = (this.gridElement.igGrid("headersTable") as any).find("th");
        columnHeaders.each((index, header) => {
            // determine key, and translate if the string is available
            let key = `HDR-${header.id.substring(columnHeaderKeyPosition)}`;
            if (key in translatedKeys) {
                $(header).find(".ui-iggrid-headertext").text(translatedKeys[key]);
                // add accessible label
                $(header).attr("aria-label", translatedKeys[key]);
            }
        });

        // the igGrid element must be visible before autSizeColumns gets called.
        // Firefox hides the element until it is fully rendered.
        this.gridElement.parent().show();
        this.gridElement.igGrid("autoSizeColumns");
    }

    retranslateGrid() {
        if (!this.gridElement) return;

        this.translateHeaders();

        let gridRows: JQuery = this.gridElement.igGrid("rows") as any;
        this.populateActionCells(gridRows);
        this.populateIconCells(gridRows);
    }
}
