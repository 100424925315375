<form novalidate [formGroup]="form" (submit)="onSubmit(form)" aria-describedby="obpa_form_err">

    <div *ngIf="searchFormModel.showFulltext">
        <div class="form-group">
            <obpa-search-form-fulltext [fulltextFormControl]="form.get('fulltext')">
            </obpa-search-form-fulltext>
        </div>
    </div>

    <div [ngSwitch]="searchFormModel.dates?.optionType">
        <ng-template [ngSwitchCase]="2">
            <div class="form-horizontal">
                <div class="form-group">
                    <div class="col-xs-6">
                        <obpa-search-form-date dateID="single" dateTitle="{{'DATE'|translate}}"
                                               [dateFormControl]="form.get('date.single')">
                        </obpa-search-form-date>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="3">
            <div class="form-horizontal">
                <div class="form-group">
                    <div class="col-xs-6">
                        <obpa-search-form-date dateID="from" dateTitle="{{'FROM DATE'|translate}}"
                                               [dateFormControl]="form.get('date.from')">
                        </obpa-search-form-date>
                    </div>
                    <div class="col-xs-6">
                        <obpa-search-form-date dateID="to" dateTitle="{{'TO DATE'|translate}}"
                                               [dateFormControl]="form.get('date.to')">
                        </obpa-search-form-date>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div *ngIf="searchFormModel.keywords">
        <div *ngFor="let keyword of searchFormModel.keywords">
            <div *ngIf="keyword.type === KeywordDataType.Date || keyword.type === KeywordDataType.DateTime">
                <div class="form-horizontal">
                    <div class="form-group">
                        <div class="col-xs-6">
                            <obpa-search-form-keyword [idPrefix]=0 [keyword]="keyword" [keywordFormControl]="form.get('keywords.From' + keyword.id)">
                            </obpa-search-form-keyword>
                        </div>
                        <div class="col-xs-6">
                            <obpa-search-form-keyword [idPrefix]=1 [keyword]="keyword" [keywordFormControl]="form.get('keywords.To' + keyword.id)">
                            </obpa-search-form-keyword>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="keyword.type !== KeywordDataType.Date && keyword.type !== KeywordDataType.DateTime">
                <div class="form-group">
                    <obpa-search-form-keyword [idPrefix]=2 [keyword]="keyword" [keywordFormControl]="form.get('keywords.' + keyword.id)">
                    </obpa-search-form-keyword>
                </div>
            </div>
        </div>
    </div>

    <div id="obpa_form_err">
        <div *ngIf="searchForm.showErrors && form.invalid" class="alert alert-danger">
            <div><strong>{{"FORM: ERRORS"|translate}}</strong></div>
            <div *ngIf="form.hasError('noDate')">{{"FORM: noDate"|translate}}</div>
            <div *ngIf="form.hasError('noKeyword')">{{"FORM: noKeyword"|translate}}</div>
            <div *ngIf="form.hasError('noDateOrKeyword')">{{"FORM: noDateOrKeyword"|translate}}</div>
            <div *ngIf="form.hasError('noDateAndKeyword')">{{"FORM: noDateAndKeyword"|translate}}</div>
        </div>
    </div>

    <div class="text-right">
        <div class="btn-group">
            <button type="submit" class="btn btn-primary">{{"SEARCH"|translate}}</button>
            <button type="button" class="btn btn-default" (click)="onReset()">{{"RESET"|translate}}</button>
        </div>
    </div>
</form>