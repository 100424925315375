import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

import { Keyword,
         SearchFormService } from "../internal";

import { KeywordDataType } from "../../shared";

@Component({
    selector: "obpa-search-form-keyword",
    templateUrl: "./search-form-keyword.component.html"
})
export class SearchFormKeywordComponent {
    constructor(public searchForm: SearchFormService) {}

    @Input()
    keyword: Keyword;

    @Input()
    keywordFormControl: FormControl;

    @Input()
    idPrefix: number;

    get keywordSimpleType(): string {
        if (this.keyword.dataset) {
            return "Dataset";
        } else if (this.keyword.mask) {
            return "Mask";
        } else {
            switch (this.keyword.type) {
                case KeywordDataType.SmallNumeric:
                    return "SmallNumeric";
                case KeywordDataType.LargeNumeric:
                    return "LargeNumeric";
                case KeywordDataType.Date:
                case KeywordDataType.DateTime:
                    return "Date";
                case KeywordDataType.Currency:
                case KeywordDataType.SpecificCurrency:
                    return "Currency";
                case KeywordDataType.Float:
                    return "Float";
                case KeywordDataType.AlphaNumeric:
                case KeywordDataType.AlphaNumericSingleTable:
                case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                // use the generic input
                case KeywordDataType.Null:
                default:
                    return "AlphaNumeric";
            }
        }
    }
    get labelPrefix(): string {
        if(this.idPrefix == 0){
            return "From ";
        } else if(this.idPrefix == 1){
            return "To ";
        }
        return "";
    }
}
